import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./App.css";
import { Features } from "./components/features/Features";
import { Projects } from "./components/projects/Projects";
import { Footer } from "./components/footer/Footer";
import { Timeline } from "./components/timeline/Timeline";
import { Testimonial } from "./components/testimonial/Testimonial";
import Navbar from "./components/header/Navbar";
import { navLinksdata } from "../src/constants/NavLinks";
import { AboutMe } from "./components/introduction/AboutMe";
import { Blog } from "./components/blog/Blog";

function App() {
  return (
    <div className="App">
      <Navbar navLinks={navLinksdata} />
      <AboutMe />
      <Features />
      <Projects />
      <Blog />
      <Timeline />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default App;
