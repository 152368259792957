import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { ExperienceMobile } from "./ExperienceMobile";
import { Experience } from "./Experience";

const ExperienceView = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {isMatch ? <ExperienceMobile /> : <Experience />}    
    </>
  );
};

export default ExperienceView;