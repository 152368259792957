import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const Footer = () => {
  return (
    <Box
      sx={{
        mt: 15,
        backgroundImage:
          "linear-gradient(90deg, rgba(109,164,210,1) 0%, rgba(49,131,157,1) 28%, rgba(131,144,175,1) 100%)",
        padding: 2,
      }}
    >
      <Typography
        variant="body2"
        color="white"
        align="center"
        fontWeight="bold"
      >
        {"Copyright © Karthik Yelisetti "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </Box>
  );
};
