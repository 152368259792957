export const navLinksdata = [
    {
      _id: 1001,
      title: "Home",
      link: "home",
    },
    {
      _id: 1002,
      title: "Features",
      link: "features",
    },
    {
      _id: 1003,
      title: "Portfolio",
      link: "portfolio",
    },
    {
      _id: 1004,
      title: "Blog",
      link: "blog",
    },
    {
      _id: 1005,
      title: "Timeline",
      link: "timeline",
    },
    {
      _id: 1006,
      title: "Testimonial",
      link: "testimonial",
    },
  ];