import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { featuresData } from "../../data/Data";
import { Cards } from "./Cards";

export const Features = () => {
  return (
    <Container id="features" component="div">
      <Stack marginTop={10}>
        <Typography textTransform={"uppercase"}>features</Typography>
        <Typography
          variant="h4"
          textTransform={"capitalize"}
          fontWeight={"bold"}
          color={"GrayText"}
        >
          what i do
        </Typography>
        <Stack
          display={"flex"}
          flexDirection={"row"}
          gap={6}
          marginTop={5}
          flexWrap={"wrap"}
        >
          {featuresData.map((item) => (
            <Cards items={item} key={item.id} />
          ))}
        </Stack>
      </Stack>
    </Container>
  );
};
