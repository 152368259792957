import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import BlogCards from "./BlogCards";
import { AppPagination } from "../pagination/AppPagination";

export const Blog = () => {
  const [blogDetails, setBlogDetails] = useState([]);
  return (
    <Container id="blog" component="div">
      <Stack marginTop={10}>
        <Box textAlign={"center"}>
          {/* <Typography textTransform={"uppercase"}>
            Blog
          </Typography> */}
          <Typography
            variant="h4"
            textTransform={"capitalize"}
            fontWeight={"bold"}
            color={"GrayText"}
          >
            my blog
          </Typography>
        </Box>
      </Stack>
      <Stack
        display={"flex"}
        flexDirection={"row"}
        marginTop={3}
        gap={6}
        flexWrap={"wrap"}
      >
        {blogDetails.map((blog) => (
          <BlogCards blogDtls={blog} key={blog.id} />
        ))}
      </Stack>
          <AppPagination setPageDetails={(p) => setBlogDetails(p)} cardType={"blog"} />
    </Container>
  )
}
