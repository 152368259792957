// Blog Data
export const blogDetails = [
  {
    id: 1,
    title: "HTML Basics",
    desc: "HTML (Hypertext Markup Language) is the standard language used to create web pages. It provides the structure and content of a webpage.",
    url: "https://incognitocoding.blogspot.com/2023/10/html-basics.html",
    image: "images/blog/html-basic.jpeg",
  },
  {
    id: 2,
    title: "Why Full Stack Web Development?",
    desc: "Full-stack web development involves working on both the front-end (client-side) and back-end (server-side) aspects of web applications.",
    url: "https://incognitocoding.blogspot.com/2023/10/why-full-stack-web-development-full.html",
    image: "images/blog/Technology-Stack-for-the-Website-Development.png",
  },
  {
    id: 3,
    title: "Full Stack Web Developers or AI?",
    desc: "It's not accurate to label one as superior or more durable than the other when comparing full-stack web developers and AI because they serve different purposes and are essential in their respective domains.",
    url: "https://incognitocoding.blogspot.com/2023/10/httpsincognitocoding.blogspot.com202310which-is-still-superior-and-more-durable.html",
    image: "images/blog/ai-for-web-dev.png",
  },
];
