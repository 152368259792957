import { React } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { FaGithub, FaGlobe } from "react-icons/fa";

const ProjectCards = ({ projectDtls }) => {
  return (
    <Card
      sx={{
        width: 345,
        height: 260,
        backgroundColor: "whitesmoke",
        boxShadow: 15,
        "&:hover": {
          backgroundColor: "lightgray",
          cursor: "pointer"
        },
      }}
    >
      {/* <CardActionArea> */}
        <CardMedia
          component="img"
          height="140"
          image={projectDtls.image}
          alt={projectDtls.title}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="text.body2"
            componenta="div"
            display={"flex"}
            justifyContent={"space-between"}
            mt={-1}
            fontWeight={"bold"}
          >
            <Box>{projectDtls.title}</Box>{" "}
            <Typography variant="h5">
              <Box>
                <a href={projectDtls.gitUrl} target="_blank" rel="noreferrer">
                  <FaGithub />
                </a>{" "}
                <a href={projectDtls.url} target="_blank" rel="noreferrer">
                  <FaGlobe />
                </a>
              </Box>
            </Typography>
          </Typography>
          <Typography variant="body2" color="text.secondary" mt={-1} sx={{fontSize: "12px"}}>
            {projectDtls.desc}
          </Typography>
        </CardContent>
      {/* </CardActionArea> */}
    </Card>
  );
};

export default ProjectCards;
