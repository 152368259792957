import {
  AppBar,
  Button,
  Grid,
  Tabs,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import DrawerComponent from "./DrawerComponent";
import { Link } from "react-scroll";

const Navbar = ({ navLinks }) => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [value, setValue] = useState();
  return (
    <AppBar
      sx={{
        backgroundImage:
          "linear-gradient(90deg, rgba(109,164,210,1) 0%, rgba(49,131,157,1) 28%, rgba(131,144,175,1) 100%)",
      }}
    >
      <Toolbar>
        {isMatch ? (
          <>
            <DrawerComponent navLink={navLinks} />
          </>
        ) : (
          <Grid sx={{ placeItems: "center" }} container>
            <Grid item xs={4}>
              <Typography variant="h6" flexGrow={1}>
                {"<Incognito Coding />"}
              </Typography>
            </Grid>
            <Grid item xs={8} display={"flex"} justifyContent={"flex-end"}>
              <Tabs
                sx={{ placeItems: "center" }}
                textColor="inherit"
                value={value}
                onChange={(e, val) => setValue(val)}
              >
                {navLinks.map((navLink, index) => (
                  <Button color="inherit" sx={{ padding: "15px" }} key={index}>
                    <Link
                      activeClass="active"
                      to={navLink.link}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      <Typography variant="text.body" alignContent={"center"}>
                        {navLink.title}
                      </Typography>
                    </Link>
                  </Button>
                ))}
              </Tabs>
            </Grid>
          </Grid>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
