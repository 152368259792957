import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { Link } from "react-scroll";

const DrawerComponent = ({ navLink }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Typography variant="h6" flexGrow={1}>
        {"<Incognito Coding />"}
      </Typography>
      <Drawer
        anchor="top"
        open={open}
        onClose={() => setOpen(!open)}
      >
        <List sx={{
          backgroundImage:
            "linear-gradient(90deg, rgba(109,164,210,1) 0%, rgba(49,131,157,1) 28%, rgba(131,144,175,1) 100%)",
        }}>
          {navLink.map((navItem, index) => (
            <ListItemButton>
              <ListItemIcon>
                <ListItemText>
                  <Link
                    activeClass="active"
                    to={navItem.link}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    key={index}
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    <Typography variant="text.body" color={"white"}>{navItem.title}</Typography>
                  </Link>
                </ListItemText>
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      </Drawer>
      <IconButton
        sx={{ marginLeft: "auto", color: "white" }}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <MenuRoundedIcon />
      </IconButton>
    </>
  );
};

export default DrawerComponent;
