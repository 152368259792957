import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { EducationMobile } from "./EducationMobile";
import { Education } from "./Education";

const EducationView = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {isMatch ? <EducationMobile /> : <Education />}    
    </>
  );
};

export default EducationView;
