import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { SkillsMobile } from './SkillsMobile';
import { Skills } from './Skills';

const SkillsView = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    return (
    <>
        {isMatch ? <SkillsMobile /> : <Skills />}
    </>
  )
}

export default SkillsView