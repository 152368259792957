// Testimonial Data

import { FaLinkedinIn } from "react-icons/fa";

export const testimonialDetails = [
  {
    title: "Sankar K",
    desc: "Certified SAFe® Product Owner/Product Manager | CSPO® | PCEP® | Business Analyst | Test Lead | MSc in Big Data Analytics",
    details: "June 23, 2022, Sankar worked with Karthik on the same team",
    recommendation:
      "We've worked closely together for almost 5 years at MetricStream rolling out new implementations, and Karthik is one of the most dedicated professionals I’ve worked with and is willing to put that extra help whenever needed. I was constantly amazed not only by his technical project management skills but also by his ability to keep the team motivated through the inevitable challenge. His contribution is valuable to the side and helped us target the deliverables on time, and I highly recommend Karthik and would love to work with him again.",
    logo: (
      <a href="https://www.linkedin.com/in/k-sankar/" target="_blank" rel="noreferrer">
        <FaLinkedinIn
          style={{
            padding: "9px",
            paddingBottom: "9px",
            border: "1px solid",
            borderRadius: "10px",
            backgroundColor: "lightgray",
            color: "blue",
          }}
        />
      </a>
    ),
  },
  {
    title: "Anitha Ravindra",
    desc: "TECHNICAL CONSULTING | CUSTOMER SUCCESS | PROGRAM MANAGEMENT | CLIENT DEVELOPMENT | PRODUCT ENHANCEMENT | STRATEGIC PLANNING | CHANGE MANAGEMENT | PROCESS IMPROVEMENT | DECISION MAKING",
    details: "May 31, 2022, Anitha managed Karthik directly",
    recommendation:
      "Karthik always exhibits an exceptionally helpful attitude with Peers and Managers for meeting the customer expectations. He makes Peers and his team feel welcome and goes above and beyond in assisting them with required support. He never had attitude to say No for any kind of tasks. He always ensures to analyze the requirements with proper impact & understand the requirement as per the customer needs - both functional / technical with all possible integrations from different modules. Best team member with excellent Technical Skills and Go getter Attitude . All The Best !!!",
    logo: (
      <a
        href="https://www.linkedin.com/in/anitha-ravindra-b35a97a2/"
        target="_blank"
        rel="noreferrer"
      >
        <FaLinkedinIn
          style={{
            padding: "9px",
            paddingBottom: "9px",
            border: "1px solid",
            borderRadius: "10px",
            backgroundColor: "lightgray",
            color: "blue",
          }}
        />
      </a>
    ),
  },
  {
    title: "Geetha Siddappa",
    desc: "Consulting | Customer Success | Project Management",
    details: "May 18, 2022, Geetha worked with Karthik on the same team",
    recommendation:
      "Karthik is a very knowledgeable and talented technical asset! In Metricstream he was one of the most sought after tech leads. Knew the systems both functionally and technically. Could implement solutions, fix issues and answer queries very quickly! Great team player to work with!",
    logo: (
      <a
        href="https://www.linkedin.com/in/geetha-siddappa-a5700299/"
        target="_blank"
        rel="noreferrer"
      >
        <FaLinkedinIn
          style={{
            padding: "9px",
            paddingBottom: "9px",
            border: "1px solid",
            borderRadius: "10px",
            backgroundColor: "lightgray",
            color: "blue",
          }}
        />
      </a>
    ),
  },
  {
    title: "Keerthi Rajendran",
    desc: "Metricstream & IBM Open Pages Consultant | Scrum Master | GRC SpecialistMetricstream & IBM Open Pages Consultant | Scrum Master | GRC Specialist",
    details: "April 24, 2022, keerthi worked with Karthik on the same team",
    recommendation:
      "I have collaborated with Karthik in Multiple Implementations while working for Metric stream. He is always my go to person for any technical assistance and design. His never say die attitude will definitely take him to next level in this technology space. I will definitely recommend Karthik in any Large Technical Implementation and Project Management.",
    logo: (
      <a
        href="https://www.linkedin.com/in/keerthi-rajendran-9528b769/"
        target="_blank"
        rel="noreferrer"
      >
        <FaLinkedinIn
          style={{
            padding: "9px",
            paddingBottom: "9px",
            border: "1px solid",
            borderRadius: "10px",
            backgroundColor: "lightgray",
            color: "blue",
          }}
        />
      </a>
    ),
  },
  {
    title: "Anas Khan",
    desc: "SE III @ Walmart Global Tech India",
    details: "April 22, 2022, Anas worked with Karthik on the same team",
    recommendation:
      "It's been a great experience working with Karthik. Being my lead, I have always felt that expertise in his technical skills. Moreover, I like the approach he follows to solve a problem which is probably a most optimal solution. You have been very supportive to me and all the teammates every time. Keep it up the good work 👏🏻",
    logo: (
      <a
        href="https://www.linkedin.com/in/anaskhan2106/"
        target="_blank"
        rel="noreferrer"
      >
        <FaLinkedinIn
          style={{
            padding: "9px",
            paddingBottom: "9px",
            border: "1px solid",
            borderRadius: "10px",
            backgroundColor: "lightgray",
            color: "blue",
          }}
        />
      </a>
    ),
  },
  {
    title: "Naresh Basa",
    desc: "Senior Delivery Manager @MetricStream | PRINCE2® Practitioner | Software Project Management | Quality Assurance | GRC Solutions | MSc in Big Data Analytics |14+ Years Exp | IT industry",
    details: "June 15, 2022, Naresh was senior to Karthik but didn't manage Karthik directly",
    recommendation:
      "I'm amazed by Karthik's technical and communication skills. He is a highly focused person as well as analytical and can add to any team he is a part of. He is well organized, diligent, innovative and a fast learner and a great team leader and handled many critical situations with ease. His energy to make things happen was contagious and it helped us achieve project milestones with great quality. One of the best team leads I had worked in MetricStream and Karthik is great asset to any team.",
    logo: (
      <a
        href="https://www.linkedin.com/in/nareshbasa/"
        target="_blank"
        rel="noreferrer"
      >
        <FaLinkedinIn
          style={{
            padding: "9px",
            paddingBottom: "9px",
            border: "1px solid",
            borderRadius: "10px",
            backgroundColor: "lightgray",
            color: "blue",
          }}
        />
      </a>
    ),
  },
  {
    title: "Jagannath Reddy",
    desc: "Sr.Cloud Operation Manager",
    details: "July 30, 2022, Jagannath worked with Karthik on the same team",
    recommendation:
      "Myself and Karthik worked together on multiple enterprise projects when he was employed at MetricStream. I found that he has the solid technical knowledge and is an expert at identifying and fixing problems. He has the ability to go deeply into the problem to identify the root cause of the issue. Very focused and result-oriented, tech smart, and highly committed to work. Excellent at interacting and engaging with customers. Most suitable person to manage customer relationships.",
    logo: (
      <a
        href="https://www.linkedin.com/in/jagannath-reddy-51092162/"
        target="_blank"
        rel="noreferrer"
      >
        <FaLinkedinIn
          style={{
            padding: "9px",
            paddingBottom: "9px",
            border: "1px solid",
            borderRadius: "10px",
            backgroundColor: "lightgray",
            color: "blue",
          }}
        />
      </a>
    ),
  },
  {
    title: "Doug Montgomery",
    desc: "Associate Vice President, GRC Solutions at MetricStream",
    details: "June 20, 2022, Doug was senior to Karthik but didn't manage Karthik directly",
    recommendation:
      "Karthik is an experienced, highly capable professional that I have worked with on very complex software solutions. His insights and knowledge were of tremendous value, with the customer often commenting on how much they respected him and the value he brought to the project and the business relationship. I recommend Karthik with reservation or caveat.",
    logo: (
      <a
        href="https://www.linkedin.com/in/doug-montgomery-963121/"
        target="_blank"
        rel="noreferrer"
      >
        <FaLinkedinIn
          style={{
            padding: "9px",
            paddingBottom: "9px",
            border: "1px solid",
            borderRadius: "10px",
            backgroundColor: "lightgray",
            color: "blue",
          }}
        />
      </a>
    ),
  },
];
