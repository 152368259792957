import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import React from "react";

export const Cards = ({ items }) => {
  return (
    <Box>
      <CardActionArea>
        <Card
          sx={{
            maxWidth: 345,
            height: 210,
            borderRadius: "10px",
            backgroundColor: "lightblue",
            color: "black",
            boxShadow: 15,
            "&:hover": {
              backgroundColor: "lightgray"
            },
          }}
        >
          <CardContent>
            <Typography variant="h4" >
              {items.icon}
            </Typography>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
            >
              {items.title}
            </Typography>
            <Typography variant="body2" color="darkblack" textAlign={"justify"} fontSize={"12px"}>
              {items.des}
            </Typography>
          </CardContent>
        </Card>
      </CardActionArea>
    </Box>
  );
};
