import { React } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { FaGlobe } from "react-icons/fa";

const BlogCards = ({ blogDtls }) => {
  return (
    <Card
      sx={{
        width: 345,
        height: 300,
        backgroundColor: "whitesmoke",
        boxShadow: 15,
        "&:hover": {
          backgroundColor: "lightgray",
          cursor: "pointer"
        },
      }}
    >
      {/* <CardActionArea> */}
        <CardMedia
          component="img"
          height={180}
          image={blogDtls.image}
          alt={blogDtls.title}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="text2.body"
            componenta="div"
            display={"flex"}
            justifyContent={"space-between"}
            mt={-1}
            fontWeight={"bold"}
          >
            <Box>{blogDtls.title}</Box>{" "}
            <Typography variant="h5">
              <Box>
                <a href={blogDtls.url} target="_blank" rel="noreferrer">
                  <FaGlobe />
                </a>
              </Box>
            </Typography>
          </Typography>
              <Typography variant="body2" color="text.secondary" mt={-1} sx={{fontSize: "12px"}}>
            {blogDtls.desc}
          </Typography>
        </CardContent>
    </Card>
  );
};

export default BlogCards;