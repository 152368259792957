import { blogDetails } from '../data/BlogData';

const servicesBlogData = {
    getData: ({from, to}) => {
        return new Promise((resolve, reject) => {
            const data = blogDetails.slice(from, to)
            resolve({
                count: blogDetails.length,
                data: data
            })
        })
    }
}

export default servicesBlogData