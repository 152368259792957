// Projects Data
export const projectDetails = [
  {
    id: 1,
    title: "YouTube clone",
    desc: "A YouTube clone is a web application or platform designed to replicate the core functionalities of YouTube, which is a popular video-sharing website.",
    url: "https://youtube-videos-clone.netlify.app/",
    image: "images/projects/youtube_clone_image.png",
    gitUrl: "https://github.com/karthikyelisetti/youtube-clone",
  },
  {
    id: 2,
    title: "Shopping Cart",
    desc: "A shopping cart website, also known as an e-commerce website, is an online platform that allows users to browse, select, and purchase products or services from a wide range of available options.",
    url: "https://brandstreet.netlify.app/",
    image: "images/projects/ShoppingCart.png",
    gitUrl: "https://github.com/karthikyelisetti/brand-street-shoppingcart",
  },
  {
    id: 3,
    title: "",
    desc: "",
    url: "",
    image: "images/coming-soon-stamp.png",
    gitUrl: "",
  },
];
