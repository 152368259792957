import React, { useEffect, useState } from 'react';
import { Pagination } from '@mui/material';
import Box from '@mui/material/Box';
import servicesData from '../../services/servicesData';
import servicesBlogData from '../../services/servicesBlogData';

const pageSize = 6;
export const AppPagination = ({setPageDetails, cardType}) => {

  const [pagination, setPagination] = useState({
    count: 0,
    from: 0,
    to: pageSize
  })

  useEffect(() => {
    if (cardType === "project") {
      servicesData.getData({from: pagination.from, to: pagination.to}).then(response => {
        setPagination({ ...pagination, count: response.count });
        setPageDetails(response.data);
      })
    } else {
      servicesBlogData.getData({from: pagination.from, to: pagination.to}).then(response => {
        setPagination({ ...pagination, count: response.count });
        setPageDetails(response.data);
      })
    }   
    
  },[cardType, pagination.from, pagination.to]);

  const handlePageChange = (event, page) => {
    const from = (page - 1) * pageSize;
    const to = (page - 1) * pageSize + pageSize;

    setPagination({...pagination, from: from, to: to});
  }

  return (
    <Box justifyContent={"center"} alignItems={"center"} display={"flex"} sx={{mt: 4}}>
      <Pagination 
        count={Math.ceil(pagination.count / pageSize )}
        onChange={handlePageChange}
      />
    </Box>
  )
}
