import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TimelineDetails from "./TimelineDetails";

export const Timeline = () => {
  return (
    <Container id="timeline" component="div">
      <Stack marginTop={15}>
        <Box textAlign={"center"}>
          <Typography textTransform={"uppercase"}>
            12+ years of experience
          </Typography>
          <Typography
            variant="h4"
            textTransform={"capitalize"}
            fontWeight={"bold"}
            color={"GrayText"}
          >
            timeline
          </Typography>
        </Box>

        <Stack
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          marginTop={5}
        >
          <TimelineDetails />
        </Stack>
      </Stack>
    </Container>
  );
};
