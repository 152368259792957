import React from "react";
import Carousel from "react-material-ui-carousel";
import { testimonialDetails } from "../../data/TestimonialData";
import { TestimonialCards } from "./TestimonialCards";

export const TestimonialCarousel = () => {
  return (
    <Carousel sx={{maxWidth: "900px", margin: "0 auto", borderRadius: "15px"}}>
      {testimonialDetails.map((recomm, index) => (
        <TestimonialCards key={index} items={recomm} />
      ))}
    </Carousel>
  );
};
