// Education Data
export const educationDetails = [
  {
    id: 1,
    title: "HeroVired",
    desc: "Full Stack Development with Cloud for Web and Mobile",
    year: "2022 - 2023",
    image: "images/projects/mvsr_engg_college.png",
  },
  {
    id: 2,
    title: "SRM University",
    desc: "Master of Technology",
    year: "2010 - 2012",
    image: "images/projects/mvsr_engg_college.png",
  },
  {
    id: 3,
    title: "MVSR Engineering College",
    desc: "Bachelor of Engineering",
    year: "2004 - 2008",
    image: "images/projects/mvsr_engg_college.png",
  },
];
