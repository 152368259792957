import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import ProjectCards from "./ProjectCards";
import { AppPagination } from "../pagination/AppPagination";

export const Projects = () => {
  const [projectDetails, setProjectDetails] = useState([]);
  return (
    <Container id="portfolio" component="div">
      <Stack marginTop={10}>
        <Box textAlign={"center"}>
          <Typography textTransform={"uppercase"}>
            visit my portfolio
          </Typography>
          <Typography
            variant="h4"
            textTransform={"capitalize"}
            fontWeight={"bold"}
            color={"GrayText"}
          >
            my projects
          </Typography>
        </Box>
      </Stack>
      <Stack
        display={"flex"}
        flexDirection={"row"}
        marginTop={3}
        gap={6}
        flexWrap={"wrap"}
      >
        {projectDetails.map((project) => (
          <ProjectCards projectDtls={project} key={project.id} />
        ))}
      </Stack>
      <AppPagination setPageDetails={(p) => setProjectDetails(p)} cardType={"project"}/>
    </Container>
  );
};
