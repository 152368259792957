import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import Typography from "@mui/material/Typography";
import { educationDetails } from "../../../data/EducationData";

export const EducationMobile = () => {
  return (
    <Timeline position="alternate">
      {educationDetails.map((education, index) => (
        <TimelineItem sx={{ height: "90px" }} key={index}>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="purple"
            fontWeight={"bold"}
            fontSize={12}
          >
            {education.year}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              {education.id !== 1 ? (
                <SchoolRoundedIcon sx={{ width: 36, height: 36 }} />
              ) : (
                <DeveloperModeIcon sx={{ width: 36, height: 36 }} />
              )}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography
              sx={{ fontSize: 13, fontWeight: "bold" }}
              component="span"
            >
              {education.title}
            </Typography>
            <Typography sx={{ fontSize: 10 }}>{education.desc}</Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};
