import * as React from "react";
import Box from "@mui/material/Box";
import bannerImg from "../../assets/images/bannerImg.png";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typewriter from "typewriter-effect";
import {
  FaLinkedinIn,
  FaReact,
  FaStackOverflow,
  FaHackerrank,
  FaGithub,
} from "react-icons/fa";
import {
  SiTailwindcss,
  SiMui,
  SiNodedotjs,
  SiExpress,
  SiMongodb,
  SiLeetcode,
} from "react-icons/si";

export default function IntroductionMobile() {
  return (
    <Container sx={{ marginTop: "75px" }} id="home" component="div">
      <Stack display={"flex"} flexDirection={"row"} flexWrap={"wrap"}>
        <Box margin={"0 auto"}>
          <img src={bannerImg} width="240px" alt="Karthik Yelisetti" />
        </Box>
        <Box marginTop={15}>
          <Typography
            variant="h1"
            textTransform={"uppercase"}
            sx={{ opacity: "9%", mt: "-100px", mb: "36px" }}
          >
            #build
          </Typography>
          <Typography sx={{ fontWeight: "bold", textTransform: "uppercase" }}>
            Welcome to my world
          </Typography>
          <Typography variant="h3" fontWeight={"bold"} color={"rebeccapurple"}>
            Hi, I'm Karthik Yelisetti
          </Typography>
          <Typography
            variant="h5"
            sx={{ mt: 3, fontWeight: "bold", color: "GrayText" }}
          >
            <Typewriter
              options={{
                strings: [
                  "a Professional Coder.",
                  "a Full Stack Web Developer.",
                  "a UI/UX Designer.",
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </Typography>
          <Typography
            sx={{
              mt: 3,
              fontWeight: "semi-bold",
              textAlign: "justify",
              fontFamily: "inherit",
              color: "inherit",
            }}
          >
            <p>
              An enthusiastic freelance software engineer from India who
              specialises in full stack web development. I love coming up with
              concepts for software and turning them into beautiful user
              interfaces. I put a lot of thought into the user experience,
              architectural design, and code quality of the things I create.
            </p>
          </Typography>
          <Stack>
            <Box marginTop={2} display={"flex"} gap={3} flexWrap={"wrap"}>
              <Box>
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    color: "GrayText",
                  }}
                >
                  find me in
                </Typography>
                <Typography variant="h5" marginTop={2}>
                  <span style={{ paddingRight: "5px" }}>
                    <a
                      href="https://www.linkedin.com/in/karthikyelisetti"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaLinkedinIn
                        style={{
                          padding: "9px",
                          paddingBottom: "9px",
                          border: "1px solid",
                          borderRadius: "10px",
                          backgroundColor: "lightgray",
                          color: "blue",
                        }}
                      />
                    </a>
                  </span>

                  <span style={{ paddingRight: "5px" }}>
                    <a
                      href="https://stackoverflow.com/users/19898320"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaStackOverflow
                        style={{
                          padding: "9px",
                          paddingBottom: "9px",
                          border: "1px solid",
                          borderRadius: "10px",
                          backgroundColor: "lightgray",
                          color: "orangered",
                        }}
                      />
                    </a>
                  </span>

                  <span style={{ paddingRight: "5px" }}>
                    <a
                      href="https://leetcode.com/karthikyelisetti/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SiLeetcode
                        style={{
                          padding: "9px",
                          paddingBottom: "9px",
                          border: "1px solid",
                          borderRadius: "10px",
                          backgroundColor: "lightgray",
                          color: "orange",
                        }}
                      />
                    </a>
                  </span>
                  <span style={{ paddingRight: "5px" }}>
                    <a
                      href="https://www.hackerrank.com/karthikyelisetti"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaHackerrank
                        style={{
                          padding: "9px",
                          paddingBottom: "9px",
                          border: "1px solid",
                          borderRadius: "10px",
                          backgroundColor: "lightgray",
                          color: "green",
                        }}
                      />
                    </a>
                  </span>
                  <span style={{ paddingRight: "5px" }}>
                    <a
                      href="https://github.com/karthikyelisetti"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaGithub
                        style={{
                          padding: "9px",
                          paddingBottom: "9px",
                          border: "1px solid",
                          borderRadius: "10px",
                          backgroundColor: "lightgray",
                          color: "black",
                        }}
                      />
                    </a>
                  </span>
                </Typography>
              </Box>

              <Box>
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    color: "GrayText",
                  }}
                >
                  best skill on
                </Typography>
                <Typography variant="h5" marginTop={2}>
                  <span style={{ paddingRight: "5px" }}>
                    <FaReact
                      style={{
                        padding: "9px",
                        paddingBottom: "9px",
                        border: "1px solid",
                        borderRadius: "10px",
                        backgroundColor: "lightgray",
                        color: "blue",
                      }}
                    />
                  </span>
                  <span style={{ paddingRight: "5px" }}>
                    <SiMui
                      style={{
                        padding: "9px",
                        paddingBottom: "9px",
                        border: "1px solid",
                        borderRadius: "10px",
                        backgroundColor: "lightgray",
                        color: "blue",
                      }}
                    />
                  </span>
                  <span style={{ paddingRight: "5px" }}>
                    <SiTailwindcss
                      style={{
                        padding: "9px",
                        paddingBottom: "9px",
                        border: "1px solid",
                        borderRadius: "10px",
                        backgroundColor: "lightgray",
                        color: "blueviolet",
                      }}
                    />
                  </span>
                  <span style={{ paddingRight: "5px" }}>
                    <SiNodedotjs
                      style={{
                        padding: "9px",
                        paddingBottom: "9px",
                        border: "1px solid",
                        borderRadius: "10px",
                        backgroundColor: "lightgray",
                        color: "darkgreen",
                      }}
                    />
                  </span>
                  <span style={{ paddingRight: "5px" }}>
                    <SiExpress
                      style={{
                        padding: "9px",
                        paddingBottom: "9px",
                        border: "1px solid",
                        borderRadius: "10px",
                        backgroundColor: "lightgray",
                        color: "darkgreen",
                      }}
                    />
                  </span>
                  <span style={{ paddingRight: "5px" }}>
                    <SiMongodb
                      style={{
                        padding: "9px",
                        paddingBottom: "9px",
                        border: "1px solid",
                        borderRadius: "10px",
                        backgroundColor: "lightgray",
                        color: "darkgreen",
                      }}
                    />
                  </span>
                </Typography>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}
