import React from "react";
import IntroductionMobile from "./IntroductionMobile";
import Introduction from "./Introduction";
import { useMediaQuery, useTheme } from "@mui/material";

export const AboutMe = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {isMatch ? (
        <>
          <IntroductionMobile />
        </>
      ) : (
        <>
          <Introduction />
        </>
      )}
    </>
  );
};
