import { projectDetails } from "../data/ProjectsData";

const servicesData = {
    getData: ({from, to}) => {
        return new Promise((resolve, reject) => {

            const data = projectDetails.slice(from, to)
            resolve({
                count: projectDetails.length,
                data: data
            })
        })
    }
}

export default servicesData