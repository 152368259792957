import {
  FaBootstrap,
  FaCss3,
  FaGithub,
  FaHtml5,
  FaPython,
  FaReact,
} from "react-icons/fa";
import {
  SiExpress,
  SiJavascript,
  SiMongodb,
  SiMui,
  SiNodedotjs,
} from "react-icons/si";

// Skills Data
export const skillDetails = [
  {
    id: 1,
    title: "GIT",
    percentage: "80",
    rating: "4",
    logo: (
      <FaGithub
        style={{
          padding: "6px",
          paddingBottom: "6px",
          border: "1px solid",
          borderRadius: "9px",
          backgroundColor: "lightgray",
          color: "black",
          boxShadow: 6,
        }}
      />
    ),
  },
  {
    id: 2,
    title: "HTML5",
    percentage: "90",
    rating: "4",
    logo: (
      <FaHtml5
        style={{
          padding: "6px",
          paddingBottom: "6px",
          border: "1px solid",
          borderRadius: "9px",
          backgroundColor: "lightgray",
          color: "purple",
        }}
      />
    ),
  },
  {
    id: 3,
    title: "CSS3",
    percentage: "70",
    rating: "3.5",
    logo: (
      <FaCss3
        style={{
          padding: "6px",
          paddingBottom: "6px",
          border: "1px solid",
          borderRadius: "9px",
          backgroundColor: "lightgray",
          color: "blue",
        }}
      />
    ),
  },
  {
    id: 4,
    title: "Bootstrap",
    percentage: "80",
    rating: "4",
    logo: (
      <FaBootstrap
        style={{
          padding: "6px",
          paddingBottom: "6px",
          border: "1px solid",
          borderRadius: "9px",
          backgroundColor: "lightgray",
          color: "black",
        }}
      />
    ),
  },
  {
    id: 5,
    title: "MUI5",
    percentage: "60",
    rating: "3",
    logo: (
      <SiMui
        style={{
          padding: "6px",
          paddingBottom: "6px",
          border: "1px solid",
          borderRadius: "9px",
          backgroundColor: "lightgray",
          color: "blue",
        }}
      />
    ),
  },
  {
    id: 6,
    title: "JavaScript",
    percentage: "70",
    rating: "3.5",
    logo: (
      <SiJavascript
        style={{
          padding: "6px",
          paddingBottom: "6px",
          border: "1px solid",
          borderRadius: "9px",
          backgroundColor: "lightgray",
          color: "blueviolet",
        }}
      />
    ),
  },
  {
    id: 7,
    title: "ReactJS",
    percentage: "60",
    rating: "3",
    logo: (
      <FaReact
        style={{
          padding: "6px",
          paddingBottom: "6px",
          border: "1px solid",
          borderRadius: "9px",
          backgroundColor: "lightgray",
          color: "blue",
        }}
      />
    ),
  },
  {
    id: 8,
    title: "Node.JS",
    percentage: "60",
    rating: "3",
    logo: (
      <SiNodedotjs
        style={{
          padding: "6px",
          paddingBottom: "6px",
          border: "1px solid",
          borderRadius: "9px",
          backgroundColor: "lightgray",
          color: "darkgreen",
        }}
      />
    ),
  },
  {
    id: 9,
    title: "Express JS",
    percentage: "60",
    rating: "3",
    logo: (
      <SiExpress
        style={{
          padding: "6px",
          paddingBottom: "6px",
          border: "1px solid",
          borderRadius: "9px",
          backgroundColor: "lightgray",
          color: "darkgreen",
        }}
      />
    ),
  },
  {
    id: 10,
    title: "MongoDB",
    percentage: "60",
    rating: "3",
    logo: (
      <SiMongodb
        style={{
          padding: "6px",
          paddingBottom: "6px",
          border: "1px solid",
          borderRadius: "9px",
          backgroundColor: "lightgray",
          color: "darkgreen",
        }}
      />
    ),
  },
  {
    id: 11,
    title: "Python",
    percentage: "60",
    rating: "3",
    logo: (
      <FaPython
        style={{
          padding: "6px",
          paddingBottom: "6px",
          border: "1px solid",
          borderRadius: "9px",
          backgroundColor: "lightgray",
          color: "darkorchid",
        }}
      />
    ),
  },
];
