import * as React from "react";
import Box from "@mui/material/Box";
import { Rating, Stack, Typography } from "@mui/material";
import { skillDetails } from "../../../data/SkillsData";

export const Skills = () => {
  return (
    <Stack>
      <Box
        sx={{ width: "100%" }}
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        textAlign={"center"}
      >
        {skillDetails.map((skillDtls, index) => (
          <Box mt={3} mr={3} key={index}>
            <Typography variant="h5">{skillDtls.logo}</Typography>
            <Rating readOnly defaultValue={skillDtls.rating} size="small" />
          </Box>
        ))}
      </Box>
    </Stack>
  );
};
