import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { TestimonialCarousel } from "./TestimonialCarousel";

export const Testimonial = () => {
  return (
    <Container id="testimonial" component="div">
      <Stack marginTop={10}>
        <Box textAlign={"center"}>
          <Typography textTransform={"uppercase"}>
            what collegues say
          </Typography>
          <Typography
            variant="h4"
            textTransform={"capitalize"}
            fontWeight={"bold"}
            color={"GrayText"}
          >
            testimonial
          </Typography>
        </Box>
        <Box mt={5}>
          <TestimonialCarousel />
        </Box>
      </Stack>
    </Container>
  );
};
