import WorkIcon from '@mui/icons-material/Work';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';

// Experience Data
export const experienceDetails = [
  {
    id: 1,
    company: "Veeva Systems",
    title: "Technical Project Manager",
    year: "Dec 2020 - Present",
    logo: <WorkIcon sx={{width: 36, height: 36}} />,
  },
  {
    id: 2,
    company: "MetricStream",
    title: "Technical Lead",
    year: "Apr 2017 - Nov 2020",
    logo: <WorkHistoryIcon sx={{width: 36, height: 36}} />,
  },
  {
    id: 3,
    company: "Star Award",
    title: "(Western Digital)",
    year: "Apr 2019",
    logo: <MilitaryTechIcon sx={{width: 36, height: 36}} />,
  },  
  {
    id: 4,
    company: "MetricStream",
    title: "Senior Member Technical Staff",
    year: "Apr 2015 - Mar 2017",
    logo: <WorkHistoryIcon sx={{width: 36, height: 36}} />,
  },
  {
    id: 5,
    company: "Spot Award",
    title: "Western Digital (Quick bug fixes)",
    year: "Apr 2017",
    logo: <EmojiEventsIcon sx={{width: 36, height: 36}}/>,
  },
  {
    id: 6,
    company: "MetricStream",
    title: "Member Technical Staff",
    year: "Jun 2013 - Mar 2015",
    logo: <WorkHistoryIcon sx={{width: 36, height: 36}} />,
  },
  {
    id: 7,
    company: "MetricStream",
    title: "Member Technical Staff Trainee",
    year: "Oct 2012 - Jun 2013",
    logo: <WorkHistoryIcon sx={{width: 36, height: 36}} />,
  },
  {
    id: 8,
    company: "Satya Infotech",
    title: "Software Developer",
    year: "Oct 2008 - Sep 2009",
    logo: <WorkHistoryIcon sx={{width: 36, height: 36}} />,
  },
];
